import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import Report from '../models/report';

@Injectable({
	providedIn: 'root'
})
export class ReportService {
	private markers;
	private userData;
	private reportList;
	private years;
	private dbPath='/reports';
	private dbPathPubliched='/reportsPublishedAll';
	private dbCount='/reportCountCat';
	reportsPublishedRef:AngularFirestoreCollection<Report>=null;
	reportsPublishedMapRef:AngularFirestoreCollection<Report>=null;
	reportCatCount:AngularFirestoreCollection=null;
	reportsRef:AngularFirestoreCollection<Report>=null;
	filterReport:AngularFirestoreCollection<Report>=null;
	getLastFive:AngularFirestoreCollection<Report>=null
	constructor(private db:AngularFirestore,private myStorage:AngularFireStorage) {
		this.reportsRef=db.collection(this.dbPath,ref => ref.orderBy('date','desc').limit(10));
		this.reportsPublishedRef=db.collection(this.dbPathPubliched,ref => ref.orderBy('date','desc').limit(10));
		this.reportsPublishedMapRef=db.collection(this.dbPathPubliched,ref => ref.orderBy('date','desc'));
		this.getLastFive=this.db.collection(this.dbPathPubliched,ref => ref.orderBy('date','desc'). limit(5));
		this.reportCatCount=this.db.collection(this.dbCount);
		//map ref
		//this.reportsRef=db.collection(this.dbPath,ref => ref.where("reportCat", "array-contains",  { ischecked:true, name : "Закана"}).where('published','==',true));


	}

	create(report: Report): any {
		return this.reportsRef.add({...report}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});

	}

	createPublished(report: Report): any {
		return this.reportsPublishedRef.add({...report}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});

	}
	//unpublished  reports
	reportGetData(id:string){
		return this.reportsRef.doc(id);
	}

	//published reports

	publishedReportGetData(id:string){
		return this.reportsPublishedRef.doc(id);
	}


	reportGetLastFive():AngularFirestoreCollection<Report>{
		return this.getLastFive;
	}
	

	//update only published reports

	update(id:string, data:any){
		return this.reportsPublishedRef.doc(id).update(data);
	}

	// delete published report 
	deletePublished(id:string,name:string){
		const filePath = 'reportImages/'+name;
		const fileRef = this.myStorage.ref(filePath).delete();
		return this.reportsPublishedRef.doc(id).delete();
	}

	delete(id:string,name:string){
		const filePath = 'reportImages/'+name;
		const fileRef = this.myStorage.ref(filePath).delete();
		return this.reportsRef.doc(id).delete();
	}


	// unpublished reports all , next , prev
	getAll():AngularFirestoreCollection<Report>{
		return this.reportsRef;
	}

	nextReport(next:any):AngularFirestoreCollection<Report>{
		return this.db.collection(this.dbPath,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(11));
	}

	prevReport(prev:any):AngularFirestoreCollection<Report>{
		return this.db.collection(this.dbPath,ref => ref.orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
	}

	//published dashboard page

	

	nextPubReport(next:any):AngularFirestoreCollection<Report>{
		return this.db.collection(this.dbPathPubliched,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(11));
	}

	prevPubReport(prev:any):AngularFirestoreCollection<Report>{
		return this.db.collection(this.dbPathPubliched,ref => ref.orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
	}		

	// published reports all , next , prev

	getAllPublichedReports():AngularFirestoreCollection<Report>{
		return this.reportsPublishedRef;
	}

	nextPublishedReport(next:any, id:any):AngularFirestoreCollection<Report>{
		if(id){
			return this.db.collection(this.dbPathPubliched,ref => ref.where("reportCat", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc').startAfter(next['date']).limit(11));
		}else{
			return this.db.collection(this.dbPathPubliched,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(11));
		}
		
	}

	prevPublishedReport(prev:any, id:any):AngularFirestoreCollection<Report>{
		if(id){
			return this.db.collection(this.dbPathPubliched,ref => ref.where("reportCat", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc').endBefore(prev['date']).limitToLast(10));

		}else{
			return this.db.collection(this.dbPathPubliched,ref => ref.orderBy('date','desc').endBefore(prev['date']).limitToLast(10));

		}
	}


	
	async filterReports(id){
		this.filterReport=this.db.collection(this.dbPathPubliched,ref => ref.where("reportCat", "array-contains",  { ischecked:true, name : id}));
		return await new Promise((resolve, reject) => {
			let filterReportListener=this.filterReport.snapshotChanges().subscribe(data => {
				this.markers = data.map(e => {
					return {
						...{id:e.payload.doc.id}
					};
				});
				resolve(this.markers);
				filterReportListener.unsubscribe();
			});
		});

	}

	async filterReportList(id){
		this.filterReport=this.db.collection(this.dbPathPubliched,ref => ref.where("reportCat", "array-contains",  { ischecked:true, name : id}).orderBy('date','desc').limit(10));
		return await new Promise((resolve, reject) => {
			let filterReportListListener=this.filterReport.snapshotChanges().subscribe(data => {
				this.reportList = data.map(e => {
					return {
						...{id:e.payload.doc.id, data:e.payload.doc.data()}
					};
				});
				resolve(this.reportList);
				filterReportListListener.unsubscribe();
			});
		});
	}


	//filter reports- rpage statistics


	async filterAndCountReports(){
		this.filterReport=this.db.collection(this.dbCount);
		return await new Promise((resolve, reject) => {
			var getData;
			let filterReportListListener=this.filterReport.valueChanges().subscribe(data => {
				getData = data[0].reportCat;
				resolve(getData);
				filterReportListListener.unsubscribe();
			});
		});
	}

	async getYearChart(){
		return await new Promise((resolve, reject) => {
			let yearChartListener=this.reportsPublishedMapRef.snapshotChanges().subscribe(date=>{
				this.years=date.map(e=>{
					var d=new Date(e.payload.doc.data().reportDate);
					return d.getFullYear()
				});
				resolve(this.years);
				yearChartListener.unsubscribe();
			});
		});

	}


	//get main category count data

	async getCountData(){
		return await new Promise((resolve, reject) => {
			var getData;
			let countSubscribe=this.reportCatCount.snapshotChanges().subscribe(data=>{
				getData=data.map(e=>{
					return e.payload.doc.data().reportCat;
				});
				resolve(getData);
				countSubscribe.unsubscribe();
			});
		});
	}


	//get data for specific id

	async getCountDataId(id){
		return await new Promise((resolve, reject) => {
			var getData;
			let countSubscribe=this.reportCatCount.snapshotChanges().subscribe(data=>{
				getData=data.map(e=>{
					console.log(e.payload.doc.data().reportCat);
					return e.payload.doc.data().reportCat;
				});
				resolve(getData);
				countSubscribe.unsubscribe();
			});
		});
	}


	//update main category count

	countUpdate(data){
		return this.reportCatCount.doc('count').update(data);
	}

	//homepage change chart
	async filterReportChart(id){
		this.filterReport=this.db.collection(this.dbPathPubliched,ref => ref.where("reportCat", "array-contains",  { ischecked:true, name : id}));
		return await new Promise((resolve, reject) => {
			let filterReportListListener=this.filterReport.snapshotChanges().subscribe(data => {
				var chartYears=data.map(e=>{
					var d=new Date(e.payload.doc.data().reportDate);
					return d.getFullYear()
				});
				resolve(chartYears);
				filterReportListListener.unsubscribe();
			});
		});
	}




}
