import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class EmailService {
	constructor(private http:HttpClient) { }

	sendSimpleCheckMail(){
		
		var email;
		this.http.post("assets/send_check_email.php",{ responseType: 'text' }).subscribe(a=>{
			console.log(a);
		});
	}


	
}
