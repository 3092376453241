import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { StaticPagesService } from 'src/app/shared/services/static-pages.service';
import {Router, ActivatedRoute} from "@angular/router";

@Component({
	selector: 'app-dashboard-page',
	templateUrl: './dashboard-page.component.html',
	styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

	faboutProject:FormGroup;
	submitted=false;
	id;
	constructor(private fb:FormBuilder, private staticpage:StaticPagesService, private rout:ActivatedRoute, private router:Router) { }

	ngOnInit(): void {
		this.createForm();
		this.id = this.rout.snapshot.paramMap.get('page');

		let staticListener=this.staticpage.staticPageGetData(this.id).subscribe(a=>{
			this.faboutProject.patchValue(a);
			staticListener.unsubscribe();
		});
	}
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "20rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: true,
		customClasses: [
		{
			name: "quote",
			class: "quote"
		},
		{
			name: "redText",
			class: "redText"
		},
		{
			name: "titleText",
			class: "titleText",
			tag: "h1"
		}
		],
	};



	get f(){
		return this.faboutProject.controls;
	}

	private createForm() {
		//inicialization
		this.faboutProject = this.fb.group({
			title: '',
			title_en:'',
			text: '',
			text_en:''
		});
	}

	onSubmit({value}){
		
		this.staticpage.update(this.id,value).then((value) => {
			console.log('Created new item successfully!');
			this.router.navigate(['dashboard']);

			this.submitted=true;

		});

	}

}
