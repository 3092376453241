import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 
import { finalize } from 'rxjs/operators';
@Component({
	selector: 'app-dashboard-reports-published-update',
	templateUrl: './dashboard-reports-published-update.component.html',
	styleUrls: ['./dashboard-reports-published-update.component.scss']
})
export class DashboardReportsPublishedUpdateComponent implements OnInit {
	id:string;
	report;
	published;
	uploadPercent;
	downloadURL;
	submitted=false;
	freportUpdate:FormGroup;
	title;
	mapData;
	constructor(
		public route:Router,
		private rout:ActivatedRoute,
		private reportService:ReportService,
		private fb: FormBuilder,private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,
		) {
	}

	ngOnInit(){
		this.createForm();
		this.id = this.rout.snapshot.paramMap.get('id');
		this.report=this.reportService.publishedReportGetData(this.id).valueChanges().subscribe(a=>{
			this.freportUpdate.patchValue({
				title:a.title,
				date:a.date,
				time:a.time,
				description:a.description,
				reportDate:a.reportDate,
				incident:a.incident,
				type:a.type,
				reportAny:a.reportAny,
				reportWhere_other:a.reportWhere_other,
				discrimination_other:a.discrimination_other,
				published:a.published,
				gender:a.gender,
				placeLive:a.placeLive,
				edu:a.edu,
				workStatus:a.workStatus,
				salary:a.salary,
				employStatus:a.employStatus,
				info:a.info,
				name_surname:a.name_surname,
				email:a.email,
				news_link:a.news_link,
				news_link_2:a.news_link_2,
				image:a.image,
			});

			this.freportUpdate.setControl('reportCat', this.fb.array(a.reportCat || []));
			this.freportUpdate.setControl('reportWhere', this.fb.array(a.reportWhere || []));
			this.freportUpdate.setControl('discriminationFor', this.fb.array(a.discriminationFor || []));

			this.report.unsubscribe();
		});
	}

	private createForm() {
		//inicialization
		this.freportUpdate=this.fb.group({
			title:'',
			date:'',
			description:'',
			reportDate:['', (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)],
			time:'',
			reportCat:this.fb.array([]),
			incident:'',
			type:'',
			reportAny:'',
			reportWhere:this.fb.array([]),
			reportWhere_other:'',
			discriminationFor:this.fb.array([]),
			discrimination_other:'',
			published:'',
			gender:'',
			placeLive:'',
			edu:'',
			workStatus:'',
			salary:'',
			employStatus:'',
			info:'',
			name_surname:'',
			email:'',
			news_link:'',
			news_link_2:'',
			image:'',
		});
	}

	is_incident:any=[{id:1, name:"Еднаш"},{id:2,name:"Неколку пати"},{id:3,name:"Често"},{id:4,name:"Постојано"},{id:5,name:"Не знам"}];
	org_type:any=[{id:1, name:"Државен/јавен сектор"},{id:2, name:"Невладин/непрофитен сектор"},{id:3,name:"Приватен бизнис сектор"}, {id:4,name:"Меѓународна институција/диполоматско претставништво"}];
	report_any:any=[{id:1,name:'Да'},{id:2,name:'Не'}];
	gender_list:any=[{id:1,name:'Машки'},{id:2,name:'Женски'}];
	place_live:any=[{id:1,name:'Урбана'},{id:2,name:'Рурална'},{id:3,name:'Полуурбана'},{id:4,name:'Скопје'}];
	education:any=[{id:1,name:'Без образование'},{id:2,name:'Основно'},{id:3,name:'Средо гимназиско'},{id:4,name:'Средно стручно'},{id:5,name:'Виша'},{id:6,name:'Факултет'},{id:7,name:'Постдипломски студии'}];
	work_status:any=[{id:1,name:'Вработен определено'},{id:2,name:'Вработен неопределено'},{id:3,name:'Вработен привремено'},{id:4,name:'Вработен хонорарно'},{id:5,name:'Сезонска работа'},{id:6,name:'Практикант'},{id:7,name:'Невработен'}];
	salary_m:any=[{id:1,name:'до 5 000 ден'},{id:2,name:'од 5 000 до 10 000 ден'},{id:3,name:'од 10 000 до 15 000 ден'},{id:4,name:'од 15 000 до 20 000 ден'},{id:5,name:'од 20 000 до 25 000 ден'},{id:6,name:'од 25 000 до 30 000 ден'},{id:7,name:'од 30 000 до 35 000 ден'},{id:8,name:'од 35 000 до 40 000 ден'},{id:9,name:'над 40 000 денн'}];


	get f() { return this.freportUpdate.controls; }


	onCheckPlublished(val){
		console.log(val);
		this.published=val;
	}

	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'reportImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					console.log(downloadURL);
					this.downloadURL=downloadURL;
					this.f.image.patchValue(this.downloadURL);
				});
			})
			).subscribe();
		

	}


	isCategorySelected(event,category){
		var array=this.freportUpdate.get('reportCat')as FormArray;
		if(event.target.checked){
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}


	isReportWhereSelected(event,answer){
		var arraywhere=this.freportUpdate.get('reportWhere')as FormArray;
		if(event.target.checked){
			arraywhere.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraywhere.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			arraywhere.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraywhere.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}


	isDiscriminationForSelected(event,answer){
		var arraydis=this.freportUpdate.get('discriminationFor')as FormArray;
		if(event.target.checked){
			this.freportUpdate.get('discriminationFor').setErrors(null);
			arraydis.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraydis.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			arraydis.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraydis.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}

	}

	onSubmit({value}) {
		console.log(value);
		this.submitted = true;
		if(value.published==true){
			this.reportService.update(this.id,value).then((value) => {
				console.log('Update item successfully!');
				this.route.navigate(['dashboard-published-reports']);
			});
		}
		
	}


}
