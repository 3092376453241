<div class="container-fluid loginHeader">
	<div class="container header-container">
		<a class="navbar-brand logo" href="/">
			<img src="assets/img/logo.png" *ngIf="translate.currentLang=='en'">
			<img src="assets/img/logo.png" *ngIf="translate.currentLang!='en'">
		</a>
		<a href="/add-report" class="addReportButton">
			<span class="report_main_btn">
			{{"Пријави нов инцидент"|translate}}</span><img src="assets/img/plus.png" height="60">
			</a>
		</div>
	</div>

	<div class="container-fluid nav-box">
	<div class="container" style="padding: 0">
		<nav class="navbar navbar-expand-lg navbar-light">
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
			<span class="navbar-toggler-icon"></span>
		</button>
			<div class="collapse navbar-collapse" id="navbarColor03" [ngbCollapse]="isCollapsed" style="text-transform: uppercase;">
				<div class="navbar-nav nav">
					<a class="nav-link" href="/">{{"Почетна"|translate}}</a>
					<a class="nav-link" href="statistics">{{"Поднесени пријави"|translate}}</a>
					<a class="nav-link" href="page/dictionary">{{"Поимник"|translate}}</a>
					<a class="nav-link" href="page/about-project">{{"За проектот"|translate}}</a>
					<a class="nav-link" href="page/contact">{{"Контакт"|translate}}</a>
						<!-- <div class="lang" >
							<span class="form-inline">
								<select class="language" #selectedLang (change)="switchLang(selectedLang.value)">
									<option *ngFor="let language of translate.getLangs()" [value]="language"
									[selected]="language === translate.currentLang">
									{{ language }}
								</option>
							</select>
						</span>
					</div> -->
				</div>
			</div>

		</nav>
</div>
	</div>