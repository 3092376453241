import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/shared/services/report.service';
import {Router, ActivatedRoute} from "@angular/router";
import { map } from 'rxjs/operators';
import { faList, faFilter, faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-frontend-statistics',
	templateUrl: './frontend-statistics.component.html',
	styleUrls: ['./frontend-statistics.component.scss']
})
export class FrontendStatisticsComponent implements OnInit {
	
	reports;
	faList = faList;
	faFilter=faFilter;
	faClock=faClock;
	allCat;
	disablePrevButton=true;
	disableNextButton;
	firstReport;
	lastReport;
	mainFirstReport;
	categories;
	objectReportLength=[];
	divStyle;
	colorAll;
	queryParamsCat
	homepagehover;
	currentCat;

	constructor(private reportservice:ReportService,private rout:ActivatedRoute,public router: Router) { }

	async ngOnInit() { 
		this.queryParamsCat=this.rout.snapshot.queryParams.catName;
		this.allCat=await this.reportservice.filterAndCountReports();
		for(var rep of this.allCat){
			this.objectReportLength.push({name:rep.name,length:rep.count});
		}
		this.categories=this.objectReportLength;

		//check parm if is there or not
		if(this.queryParamsCat){
			this.reports=await this.reportservice.filterReportList(this.queryParamsCat);

			for (var m of this.categories){
			if(m.name==this.queryParamsCat){
				if(m.length<11){
					this.disableNextButton=true;
				}else{
					this.disableNextButton=true;
				}
			}
		}
		}else{
			this.getAllReports();
		}
	}

	async filterCat(cat,i){
		this.currentCat=cat;
		this.router.navigate(['/statistics']);
		this.queryParamsCat='';
		this.reports=await this.reportservice.filterReportList(cat);	
		this.firstReport=this.reports[0].data;
		this.mainFirstReport=this.reports[0].data;
		this.lastReport=this.reports[this.reports.length-1].data;	
		this.divStyle=i;
		this.colorAll='';
		for (var m of this.categories){
			if(m.name==cat){
				if(m.length<11){
					this.disableNextButton=true;
				}else{
					this.disableNextButton=false;
				}
			}
		}
	}

	async getAllCat(){
		this.getAllReports();
		this.colorAll='hover';
		this.divStyle='';
	}

	getAllReports(){
		this.disableNextButton=false;
		let listener = this.reportservice.getAllPublichedReports().snapshotChanges().pipe(map(actions => actions.map(e => {
			return {id:e.payload.doc.id, data:e.payload.doc.data()}
		}))
		).subscribe(data => {			
			this.reports = data;
			this.currentCat='';
			this.firstReport=data[0].data;
			this.mainFirstReport=data[0].data;
			this.lastReport=data[data.length-1].data
			listener.unsubscribe();
		});

	}

	nextReportPage(){
		let listener=this.reportservice.nextPublishedReport(this.lastReport,this.currentCat).snapshotChanges().pipe(
			map(actions => actions.map(a => {
				return {id:a.payload.doc.id, data:a.payload.doc.data()}
			}))
			).subscribe(data => {
				this.disableNextButton = true;
				var removeCount = 0;
				if (data.length == 11) {
					removeCount = 1;
					this.disableNextButton = false;
				}else{
					this.disablePrevButton=true;
				}
				var newArrayReport = [];
				for(let i=0;i<data.length-removeCount;i++){
					newArrayReport.push(data[i]);	
				}
				this.reports = newArrayReport;
				this.firstReport=newArrayReport[0].data;
				this.lastReport=newArrayReport[newArrayReport.length-1].data;
				this.disablePrevButton=false;
				listener.unsubscribe();
			});

		}

		prevReportPage(){
			let listener=this.reportservice.prevPublishedReport(this.firstReport,this.currentCat).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					return {id:a.payload.doc.id, data:a.payload.doc.data()}
				}))
				).subscribe(data => {
					this.reports = data;
					this.firstReport=data[0].data;
					this.lastReport=data[data.length-1].data;
					this.disableNextButton=false;
					if(this.mainFirstReport.title===this.firstReport.title){
						this.disablePrevButton=true;
					}
					listener.unsubscribe();
				});
			}







		}
