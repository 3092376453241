import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ReportService } from 'src/app/shared/services/report.service';
import exporting from 'highcharts/modules/exporting';
import { TranslateService } from '@ngx-translate/core';


exporting(Highcharts);

@Component({
	selector: 'app-highcharts',
	templateUrl: './highcharts.component.html',
	styleUrls: ['./highcharts.component.scss']
})
export class HighchartsComponent implements OnChanges {
	years;
	combineYears;
	combineSeries=[];
	isHighcharts = typeof Highcharts === 'object';
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options;
	updateFlag = false;
	@Input() categoryName:string;
	constructor(private reportservice:ReportService, public translate:TranslateService) { 
	}

	async ngOnChanges(changes: SimpleChanges) {
		if(changes['categoryName'].currentValue!=''){
			this.categoryName=changes['categoryName'].currentValue;
			this.years=await this.reportservice.filterReportChart(this.categoryName);
			this.combineYears=[];
			this.combineSeries=[];
			this.createChart();
			this.updateFlag = true;
		}

	}
	async ngOnInit(){
		this.years=await this.reportservice.getYearChart();
		this.createChart();
	}

	createChart(){
		this.combineYears=[];
		let sortArr = this.years.sort();
		var current=null;
		var count=0;
		var title;
		for (var i = 0; i < sortArr.length; i++) {
			if (sortArr[i] != current) {
				if (count > 0) {
					this.combineSeries.push(count);
					this.combineYears.push(current);
				}
				current = sortArr[i];
				count = 1;
			} else {
				count++;
			}
		}
		if (count > 0) {
			this.combineSeries.push(count);
			this.combineYears.push(current);
			title=this.combineYears[0]+' - '+ this.combineYears[this.combineYears.length-1];
		}else{
			title="Категоријата е празна"
		}
		//console.timeEnd('Function #1');

		this.Highcharts.setOptions({
			chart: {
				style: {
					fontFamily: 'Arsenal'
				}
			}
		});
		this.chartOptions = {
			title: {
				// text: this.translate.instant("Поднесени пријави по година")
				text:''
			},
			subtitle: {
				text: title
			},
			xAxis: {
				categories: this.combineYears,
				visible:false
			},
			yAxis: {
				title: {
					// text: this.translate.instant("Број на поднесени пријави"),
					text:''
				},
				gridLineWidth: 0,
				visible:false
				
			},
			tooltip: {
				formatter: function() {
					return 'Година: '+'<b>'+this.x+'</b>'+'<br/>'+
					this.series.name+': '+'<b>'+this.y+'</b>'
				}
			},

			series: [{
				showInLegend: false,  
				data: this.combineSeries,
				name:"Број на поднесени пријави",
				type: 'bar',
				color:'#e6e6e6',
				states: {
					hover: {
						color: '#b3b3b3',

					}
				},
				dataLabels: {
                enabled: true,
                
            }
			}],

			exporting: {
				buttons: {
					contextButton: {
						menuItems: [
						'viewFullscreen', 'separator', 'downloadPNG',
						'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
						]
					},
				},
				enabled: false,
			},
			navigation: {
				buttonOptions: {
					align: 'right',
					verticalAlign: 'top',
					y: 0
				}
			},

			credits: {
				enabled: false
			},


		}

	}


}
