<app-frontend-header></app-frontend-header>
<form [formGroup]="freportAdd" (ngSubmit)="onSubmit(freportAdd)">
<div class="before-page">
	<div class="container page">
		<div class="row">
			<div class="col-12 title">{{'Поднеси нова пријава'| translate}}</div>
			<div class="col-12 req">{{"Полињата означени со * се задолжителни"|translate}}</div>
		</div>
		<div class="row">
			<div class="col-md-7 col-xs-12">
				<div class="form-group">
					<label class="label">{{"Наслов"|translate}}</label>
					<span class="mandatory">*</span>
					<input type="text" id="title" formControlName="title" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
					<div *ngIf="submitted && f.title.errors" class="invalid-feedback">
						<div *ngIf="f.title.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="report_desc" class="label">{{"Ве молиме наведете што точно ви се случило, споделете ја вашата приказна со нас "|translate}}
					</label>
					<span class="mandatory">*</span>
					<textarea class="form-control" id="description" formControlName="description"
					[ngClass]="{ 'is-invalid': submitted && f.description.errors }" style="min-height: 250px"></textarea>
					<div *ngIf="submitted && f.description.errors" class="invalid-feedback">
						<div *ngIf="f.description.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>


				<div class="form-group">
					<label for="blog_date" class="label">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
					<div class="row">
						<div class="col-6">		<input type="date" formControlName="reportDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.reportDate.errors }" />
						</div>
						<div class="col-6">		<input type="type" formControlName="time" placeholder="{{'Пр. 12:00, 12:35'|translate}}" class="form-control">
						</div>
					</div>
					<div *ngIf="submitted && f.reportDate.errors" class="invalid-feedback">
						<div *ngIf="f.reportDate.errors.required">									<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="reportCat" class="label">{{"Категории"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let category of categories; let i=index">
						<input type="checkbox"
						id="bla+{{category.id}}"  name="reportCat"  [value]="category.name"
						(change)="isCategorySelected($event,category.name)" id="{{category.name}}">
						<label for="{{category.name}}" style="cursor: pointer;"> {{category.name | translate}}</label>
					</div>

					<div *ngIf="submitted && !checkedCat" class="invalid-feedback mandatory_line" >
						<div *ngIf="submitted && !checkedCat">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>

				</div>
				<div class="form-group">
					<label for="report_a_y" class="label">{{"Дали ова беше инцидент или се случува континуирано? "|translate}}<span class="mandatory">*</span></label>
					<div *ngFor="let i_i of is_incident" >
						<input type="radio"  name="incident" formControlName="incident" value="{{i_i.name}}" id="{{i_i.name}}">
						<label for="{{i_i.name}}">{{i_i.name | translate}}</label>
					</div>
					<div *ngIf="submitted && f.incident.errors" class="invalid-feedback mandatory_line">									
						<div *ngIf="f.incident.errors.required"><span>{{'Ова поле е задолжително'|translate}}</span></div>
					</div>
				</div>


				<div class="form-group">
					<label for="report_type" class="label">{{"Тип на организација [работодавач]"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let type of org_type" >
						<input type="radio"  name="type" formControlName="type" value="{{type.name}}" id="{{type.name}}">
						<label for="{{type.name}}"> {{type.name | translate}}</label>
					</div>
					<div *ngIf="submitted && f.type.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.type.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="report_police" class="label">{{"Дали случајов го имате пријавено на друго место?"|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let reportAny of report_any" >
						<input type="radio" name="reportAny" formControlName="reportAny" 
						value="{{reportAny.name}}" id="{{reportAny.id}}_{{reportAny.name}}">
						<label for="{{reportAny.id}}_{{reportAny.name}}">{{reportAny.name | translate}}</label>
					</div>

					<div *ngIf="submitted && f.reportAny.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.reportAny.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="report_where" class="label">{{"Доколку имате пријавено, каде го направивте тоа?"|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let reportWhere of report_where">
						<input type="checkbox" name="reportWhere" value="reportWhere.name" 
						(change)="isReportWhereSelected($event,reportWhere.name)" id="{{reportWhere.id}}_{{reportWhere.name}}">
						<label for="{{reportWhere.id}}_{{reportWhere.name}}">{{reportWhere.name | translate}}</label>
					</div>
					<div *ngIf="submitted && !checkedWhere" class="invalid-feedback mandatory_line" >
						<div *ngIf="submitted && !checkedWhere">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="label">Доколку вашиот одговор е Друго,<br> ве молиме наведете каде имате пријавено</label>
					<input type="text" id="reportWhere_other" formControlName="reportWhere_other" class="form-control" />
					
				</div>

				<div class="form-group">
					<label class="label">Основ за дискриминација.<br> Ве молиме наведете поради што сметате дека сте дискриминирани</label>
					<span class="mandatory">*</span>
					<div *ngFor="let discrimination of discrimination_for">
						<input type="checkbox" name="discrimination" value="discrimination.name" 
						(change)="isDiscriminationForSelected($event,discrimination.name)" id="{{discrimination.id}}_{{discrimination.name}}">
						<label for="{{discrimination.id}}_{{discrimination.name}}">{{discrimination.name | translate}}</label>
					</div>
					<div *ngIf="submitted && !checkedDis" class="invalid-feedback mandatory_line" >
						<div *ngIf="submitted && !checkedDis">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>

				</div>
				<div class="form-group">
					<label for="report_desc" class="label">Ако сте лошо третирани поради друга причина,<br> ве молиме наведете ја подолу</label>
					<textarea class="form-control" id="discrimination_other" formControlName="discrimination_other"></textarea>
					
				</div>

				
			</div>
			<div class="col-md-5 col-xs-12">
			<div style="font-size: 20px"><label class="notMandatory">{{"Податоци за статистика"|translate}}</label>
			<div style="line-height: 1;font-size: 14px;padding-bottom: 10px;">(Овие податоци не се прикажуваат никаде, само ни се потребни при статистика во нашите истражувања.)</div>
				</div>

				<div class="form-group">
					<label for="gender" class="label">{{"Пол"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let gender of gender_list" >
						<input type="radio"  name="gender" formControlName="gender" value="{{gender.name}}" id="{{gender.name}}">
						<label for="{{gender.name}}"> {{gender.name | translate}}</label>
					</div>
					<div *ngIf="submitted && f.gender.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.gender.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>

				</div>
				<div class="form-group">
					<label for="placeLive" class="label">{{"Место на живеење"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let placeLive of place_live" >
						<input type="radio"  name="placeLive" formControlName="placeLive" value="{{placeLive.name}}" id="{{placeLive.name}}">
						<label for="{{placeLive.name}}"> {{placeLive.name | translate}}</label>
					</div>

					<div *ngIf="submitted && f.placeLive.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.placeLive.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class=" form-group">
					<label for="edu" class="label">{{"Кое е вашето последно завршено образование"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let edu of education" >
						<input type="radio"  name="edu" formControlName="edu" value="{{edu.name}}" id="{{edu.name}}">
						<label for="{{edu.name}}"> {{edu.name | translate}}</label>
					</div>

					<div *ngIf="submitted && f.edu.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.edu.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="workStatus" class="label">{{"Работен однос"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let workStatus of work_status" >
						<input type="radio"  name="workStatus" formControlName="workStatus" value="{{workStatus.name}}" id="{{workStatus.name}}">
						<label for="{{workStatus.name}}"> {{workStatus.name | translate}}</label>
					</div>
					<div *ngIf="submitted && f.workStatus.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.workStatus.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="salary" class="label">{{"Лични месечни приходи"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let salary of salary_m" >
						<input type="radio"  name="salary" formControlName="salary" value="{{salary.name}}" id="{{salary.name}}">
						<label for="{{salary.name}}"> {{salary.name | translate}}</label>
					</div>
					<div *ngIf="submitted && f.salary.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.salary.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="employStatus" class="label">{{"Работен стаж"|translate}}</label>
					<span class="mandatory">*</span>
					<input type="text" id="employStatus" formControlName="employStatus" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.employStatus.errors }" />
					<div *ngIf="submitted && f.employStatus.errors" class="invalid-feedback">
						<div *ngIf="f.employStatus.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="info" class="label">{{"Како се информиравте за пријавава?"|translate}}</label>
					<input type="text" id="info" formControlName="info" class="form-control"
					 />
					
				</div>



				<div style="font-size: 20px"><label class="notMandatory">{{"Незадолжителни полиња"|translate}}</label>
				</div>
				<div>
					<div>{{"Име и презиме"|translate}}</div>
					<input class="form-control" type="text" name="name_surname" formControlName="name_surname">
				</div>
				<div>
					<div>{{"Email"|translate}}</div>
					<input class="form-control" type="text" name="email" formControlName="email">
				</div>
						
				<div>
					<div>{{"Линк од вести"|translate}}</div>
					<input class="form-control" type="text" name="news_link" formControlName="news_link">
				</div>
				<div>
					<div>{{"Надворешен видео линк"|translate}}</div>
					<input class="form-control" type="text" name="news_link_2" formControlName="news_link_2">
				</div>
				<div>
					<div>{{"Прикачи фотографија"|translate}}</div>
					<input class="form-control" type="file"  (change)="uploadImage($event, f.title)" accept=".png,.jpg" >
					<div class="progress">
						<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
					</div>

				</div>
				<div class="group">
					
					<ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"  
					(success)="handleSuccessRecaptcha($event)" [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }">
					</ngx-recaptcha2>
					<div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
						<div *ngIf="f.recaptcha.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span></div>
						</div>
					
					</div>
				<div class="col-12 group">
				<button class="btn btn-primary" type="submit">{{"Поднеси пријава"|translate}}</button>


			</div>
			</div>
			


		</div>

	</div>
</div>

</form>
<app-frontend-footer></app-frontend-footer>