<app-frontend-header></app-frontend-header>
<div class="before-page">
	<div class="container page">
		<div class="row">
			<div class="col-12">
				<div class="title">{{"Приказ на пријави"|translate}}</div>
			</div>
			<div class="col-md-9 col-xs-12">
				<div class="report_filter">
				</div>
				<div *ngIf="reports?.length==0" style="padding: 20px 0px">Категоријата е празна, моментално нема поднесено пријави.</div>
					<div class=" front_list_grid" 
					*ngFor="let rep of reports; let i = index" [class.active]="i == currentIndex" >
					<div class="row">
						<div class="col-3">
							<div *ngIf="rep.image; else defaultImage" class="report_image_box"><div style="background-image: url('{{ rep.data.image }}');" class="report_image_box"></div>
						</div>
						<ng-template #defaultImage class="report_image_box">
							<div style="background-image: url('/assets/img/no_image.png');" class="report_image_box"></div></ng-template>
						</div>
						<div class="col-md-9 col-xs-12"> 
							<div class="report_data">
								<div class="report_date"> <span style="color:#000000;padding:5px"><fa-icon [icon]="faClock"></fa-icon> </span>
									{{rep.data.reportDate | date }}</div>
									<a [routerLink]="['/statistics',rep.id]">
										<div class="report_title">{{rep.data.title}}</div>
										<div>{{ (rep.data.description.length>100)? (rep.data.description | slice:0:100):(rep.data.description)  }} <span class="read_more">{{"...Прочитајте повеќе..."| translate }}</span>
										</div>
									</a>
									<br>

								</div>
							</div>
						</div>
				</div>
				<button class="btn btn-primary btn-sm float-left" [disabled]="disablePrevButton" (click)="prevReportPage()">{{"Претходна страница"|translate}}</button>
				<button class="btn btn-primary btn-sm float-right" (click)="nextReportPage()" [disabled]="disableNextButton">{{"Следна страница"|translate}}</button>
			</div>
			<div class="col-md-3 col-xs-12" style="border-left: 1px solid #e6e6e6;">
				<div class="report_filter" style="padding: 10px"><fa-icon [icon]="faFilter"></fa-icon> {{"Филтрирај по категорија"|translate}}</div>
				<div class="list-group-item list-group-hover {{colorAll}}" (click)="getAllCat()">
					{{"Сите категории"|translate}}</div>
					<div *ngFor="let report of categories; let i = index" [class.active]="i == currentIndex">
						<div class="list-group-item list-group-hover" (click)="filterCat(report.name, i)" [ngClass]="[(divStyle === i || queryParamsCat==report.name)?'hover':'']">{{report.name|translate}} ({{report.length}})</div>		
					</div>
				</div>
			</div>
		</div>
	
	<app-frontend-footer></app-frontend-footer>
</div>
