import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ReportService } from 'src/app/shared/services/report.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-dashboard-reports-published',
  templateUrl: './dashboard-reports-published.component.html',
  styleUrls: ['./dashboard-reports-published.component.scss']
})
export class DashboardReportsPublishedComponent implements OnInit {

  reportPublished:any;
	faCheck = faCheck;
	faEdit=faEdit;
	faTrash=faTrash;
	lastReport;
	mainFirstReport;
	firstReport;
	disableNextButton;
	disablePrevButton=true;
	url;
	constructor(
		public authService: AuthService,
		public router: Router,
		public ngZone: NgZone,
		private report:ReportService
		) { }


	ngOnInit(): void {
		this.getAllReports();
		this.url=window.location.pathname;
		
		if(this.url==='/dashboard-published-reports'){
			this.url='active';
		}else{
			this.url='';
		}

	}

	getAllReports(){
		let listener = this.report.getAllPublichedReports().snapshotChanges().pipe(

			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				if(data.length>10){
					this.disableNextButton=false;
				}else{
					this.disableNextButton=true;

				}
				this.reportPublished = data;
				this.firstReport=data[0];
				this.mainFirstReport=data[0];
				this.lastReport=data[data.length-1];

				listener.unsubscribe();
			});
		}


		nextReportPage(){
			
			let listener=this.report.nextPubReport(this.lastReport).snapshotChanges().pipe(
				map(actions => actions.map(a => {
					const data = a.payload.doc.data();
					const id = a.payload.doc.id;
					return { id, ...data };
				}))
				).subscribe(data => {
					this.disableNextButton = true;
					var removeCount = 0;
					if (data.length == 11) {
						removeCount = 1;
						this.disableNextButton = false;
					}
					var newArrayReport = [];
					for(let i=0;i<data.length-removeCount;i++){
						newArrayReport.push(data[i]);	
					}
					this.reportPublished = newArrayReport;
					this.firstReport=newArrayReport[0];
					this.lastReport=newArrayReport[newArrayReport.length-1];

					this.disablePrevButton=false;
					listener.unsubscribe();
				});

			}

			prevReportPage(){
				let listener=this.report.prevPubReport(this.firstReport).snapshotChanges().pipe(
					map(actions => actions.map(a => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					}))
					).subscribe(data => {
						this.reportPublished = data;
						this.firstReport=data[0];
						this.lastReport=data[data.length-1];
						this.disableNextButton=false;
						if(this.mainFirstReport.title===this.firstReport.title){
							this.disablePrevButton=true;
						}
						listener.unsubscribe();
					});
				}


				addReport(){
					this.router.navigate(['dashboard-add-report']);
				}



				deleteReport(id, title){
					this.report.deletePublished(id,title);
					window.alert('Успешно избришано');
					this.router.navigate(['dashboard']);

					//window.location.reload();
				}





}
