import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router } from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';
import Report from 'src/app/shared/models/report';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 

import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

@Component({
	selector: 'app-dashboard-add-report',
	templateUrl: './dashboard-add-report.component.html',
	styleUrls: ['./dashboard-add-report.component.scss'],

})
export class DashboardAddReportComponent implements OnInit {
	report:Report=new Report();
	submitted = false;
	freportAdd:FormGroup;
	mapDataValid:boolean=false;
	reportImage:any;
	uploadPercent;
	downloadURL;
	checkedCat;
	checkedReportAhre;
	checkedDisc;

	constructor(private reportService:ReportService,
		public router:Router,
		private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,
		private fb:FormBuilder
		) {

	}
	ngOnInit(): void {
		this.createForm();
		

	}

	categories:any=[
	{ name:'Вработување', ischecked:false},
	{ name:'Отпуштање',ischecked:false},
	{ name:'Унапредување',ischecked:false},
	{ name:"Вознемирување",ischecked:false},
	{ name:"Плата",ischecked:false},
	{ name:"Работни задачи",ischecked:false},
	{ name:"Принуден одмор",ischecked:false},
	{ name:"Професионална надградба",ischecked:false},
	{ name:"Работни поволности",ischecked:false},
	];
	is_incident:any=[{id:1, name:"Еднаш"},{id:2,name:"Неколку пати"},{id:3,name:"Често"},{id:4,name:"Постојано"},{id:5,name:"Не знам"}];
	org_type:any=[{id:1, name:"Државен/јавен сектор"},{id:2, name:"Невладин/непрофитен сектор"},{id:3,name:"Приватен бизнис сектор"}, {id:4,name:"Меѓународна институција/диполоматско претставништво"}];
	report_any:any=[{id:1,name:'Да'},{id:2,name:'Не'}];
	report_where:any=[{name:"Работодавец", ischecked:false},
	{name:"Трудова инспекција", ischecked:false},{name:"Јавен обвинител",ischecked:false},{name:"Синдикат",ischecked:false},{name:"Народен правобранител", ischecked:false},{name:"Невладина организација", ischecked:false},{name:"Друго", ischecked:false}];
	discrimination_for:any=[{name:"Пол", ischecked:false},
	{name:"Родителскиот статус и семејни одговорности", ischecked:false},{name:"Религија",ischecked:false},{name:"Партиска припадност/неприпадност",ischecked:false},{name:"Бременост", ischecked:false},{name:"Раса", ischecked:false},{name:"Брачен статус", ischecked:false},{name:"Возараст", ischecked:false},{name:"Етничка припадност", ischecked:false},{name:"Сексуална ориентација", ischecked:false},{name:"Попреченост", ischecked:false}];

	//for statistics

		gender_list:any=[{id:1,name:'Машки'},{id:2,name:'Женски'}];
		place_live:any=[{id:1,name:'Урбана'},{id:2,name:'Рурална'},{id:3,name:'Полуурбана'},{id:4,name:'Скопје'}];
		education:any=[{id:1,name:'Без образование'},{id:2,name:'Основно'},{id:3,name:'Средо гимназиско'},{id:4,name:'Средно стручно'},{id:5,name:'Виша'},{id:6,name:'Факултет'},{id:7,name:'Постдипломски студии'}];
		work_status:any=[{id:1,name:'Вработен определено'},{id:2,name:'Вработен неопределено'},{id:3,name:'Вработен привремено'},{id:4,name:'Вработен хонорарно'},{id:5,name:'Сезонска работа'},{id:6,name:'Практикант'},{id:7,name:'Невработен'}];
		salary_m:any=[{id:1,name:'до 5 000 ден'},{id:2,name:'од 5 000 до 10 000 ден'},{id:3,name:'од 10 000 до 15 000 ден'},{id:4,name:'од 15 000 до 20 000 ден'},{id:5,name:'од 20 000 до 25 000 ден'},{id:6,name:'од 25 000 до 30 000 ден'},{id:7,name:'од 30 000 до 35 000 ден'},{id:8,name:'од 35 000 до 40 000 ден'},{id:9,name:'над 40 000 денн'}];


	createForm(){
		this.freportAdd=this.fb.group({
			title:['',Validators.required],
			description:['',Validators.required],
			reportDate:['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
			time:'',
			reportCat:this.fb.array( this.categories,[Validators.required] ),
			incident:['',Validators.required],
			type:['',Validators.required],
			reportAny:['',Validators.required],
			reportWhere:this.fb.array( this.report_where,[Validators.required] ),
			reportWhere_other:'',
			discriminationFor:this.fb.array( this.discrimination_for,[Validators.required] ),
			discrimination_other:'',
			published:false,
			gender:'',
			placeLive:'',
			edu:'',
			workStatus:'',
			salary:'',
			employStatus:'',
			info:'',
			name_surname:'',
			email:'',
			news_link:'',
			news_link_2:'',
			image:'',
			date:new Date()
		});

	}

	get f(){

		return this.freportAdd.controls;
	}


	isCategorySelected(event,category){
		var array=this.freportAdd.get('reportCat')as FormArray;
		if(event.target.checked){
			this.checkedCat=true;
			this.freportAdd.get('reportCat').setErrors(null);
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			this.checkedCat=false;
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}


	isReportWhereSelected(event,answer){
		var arraywhere=this.freportAdd.get('reportWhere')as FormArray;
		if(event.target.checked){
			this.freportAdd.get('reportWhere').setErrors(null);
			arraywhere.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraywhere.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			arraywhere.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraywhere.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}

	isDiscriminationForSelected(event,answer){
		var arraydis=this.freportAdd.get('discriminationFor')as FormArray;
		if(event.target.checked){
			this.freportAdd.get('discriminationFor').setErrors(null);
			arraydis.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraydis.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			arraydis.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraydis.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}

	}


	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'reportImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available

		let imageListener=task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					this.downloadURL=downloadURL;
					this.f.image.patchValue(this.downloadURL);

				});
			})
			).subscribe();
		//imageListener.unsubscribe();

	}

	//save Report
	onSubmit({value}) {
		console.log(value);
		this.submitted = true;
		// if (this.freportAdd.invalid) {
		// 	return;
		// }

		if(this.downloadURL)
		{
			this.f.image.patchValue(this.downloadURL);
		}	
		this.reportService.create(value).then((value) => {
			console.log('Created new item successfully!');
			this.router.navigate(['dashboard']);
		});
	}




}