export default class Report{
	id:string;
	title:string;
	description:string;
	reportDate:any;
	time:string;
	reportCat:any;
	reportCategories:any;
	incident:string;
	type:string;
	reportAny:string;
	reportWhere:string[];
	reportWhere_other:any;
	discriminationFor:any;
	discrimination_other:any;
	published=false;
	image:any;
	gender:string;
	placeLive:string;
	edu:string;
	workStatus:string;
	salary:string;
	employStatus:string;
	info:string;
	name_surname:string;
	email:string;
	news_link:string;
	news_link_2:string;
	date:any;
	acceptedToSend=false;
}