import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/shared/services/report.service';
import {Router, ActivatedRoute} from "@angular/router";
import { faList, faMapMarkedAlt, faFilter, faCalendarAlt, faMapMarkerAlt,faClock } from '@fortawesome/free-solid-svg-icons';
declare const L: any; 
import Geocoder from 'leaflet-control-geocoder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
	selector: 'app-frontend-statistics-post',
	templateUrl: './frontend-statistics-post.component.html',
	styleUrls: ['./frontend-statistics-post.component.scss']
})
export class FrontendStatisticsPostComponent implements OnInit {

	id;
	reportData;
	reportWhere;
	faCalendarAlt=faCalendarAlt;
	faClock=faClock;
	lastFiveReports;
	discriminationFor;
	
	constructor(private reportservice:ReportService, private rout:ActivatedRoute, private router:Router) { }
	

	async ngOnInit() {
		this.id = this.rout.snapshot.paramMap.get('id');
		let reportListener=this.reportservice.publishedReportGetData(this.id).valueChanges().subscribe(a=>{
			this.reportData=a;
			reportListener.unsubscribe();
			for (var i=0;i<a.reportWhere.length;i++){
				if(a.reportWhere[i]['ischecked']){
					this.reportWhere=true;
				}
			}

			for (var i=0;i<a.discriminationFor.length;i++){
				if(a.discriminationFor[i]['ischecked']){
					this.discriminationFor=true;
				}
			}
		});
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.getLastFiveReports();
	}


	getLastFiveReports(){
		let lastFiveListener=this.reportservice.reportGetLastFive().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { id, ...data };
			}))
			).subscribe(data => {
				this.lastFiveReports=data;
				lastFiveListener.unsubscribe();
			});
		}
	}
