<app-frontend-header></app-frontend-header>
<div class="before-page">
<div class="container page">
	<div class="row">
		<div class="col-12">
			<div class="title">{{pageData?.title}}</div>
			 <span [innerHTML]="pageData?.text"></span>
		</div>
	</div>
</div>
<app-frontend-footer></app-frontend-footer>
</div>

