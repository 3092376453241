import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import {DashboardReportsPublishedComponent} from '../../components/dashboard-reports-published/dashboard-reports-published.component';
import {DashboardReportsPublishedUpdateComponent} from '../../components/dashboard-reports-published-update/dashboard-reports-published-update.component';

import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component';
import {DashboardAddReportComponent} from '../../components/dashboard-add-report/dashboard-add-report.component';
import {FrontendAddReportSuccessComponent} from '../../components/frontend-add-report-success/frontend-add-report-success.component';
import {DashboardUpdateReportComponent} from '../../components/dashboard-update-report/dashboard-update-report.component';
import {DashboardBlogComponent} from '../../components/dashboard-blog/dashboard-blog.component';
import {DashboardBlogListComponent} from '../../components/dashboard-blog-list/dashboard-blog-list.component';
import {DashboardBlogUpdateComponent} from '../../components/dashboard-blog-update/dashboard-blog-update.component';
import {DashboardPageComponent} from '../../components/dashboard-page/dashboard-page.component';
import {DashboardPublicationsComponent} from '../../components/dashboard-publications/dashboard-publications.component';
import {DashboardPublicationListComponent} from '../../components/dashboard-publication-list/dashboard-publication-list.component';
import {DashboardPublicationUpdateComponent} from '../../components/dashboard-publication-update/dashboard-publication-update.component';

//import { DashboardDictionaryComponent } from '../../components/dashboard-dictionary/dashboard-dictionary.component';
//import { DashboardContactComponent } from '../../components/dashboard-contact/dashboard-contact.component';

import {FrontendAddReportComponent} from '../../components/frontend-add-report/frontend-add-report.component';
import {FrontendPageComponent} from '../../components/frontend-page/frontend-page.component';
import {FrontendHomeComponent} from '../../components/frontend-home/frontend-home.component';
import {FrontendStatisticsComponent} from '../../components/frontend-statistics/frontend-statistics.component';
import {FrontendStatisticsPostComponent} from '../../components/frontend-statistics-post/frontend-statistics-post.component';
import {FrontendPublicationsComponent} from '../../components/frontend-publications/frontend-publications.component';
import {FrontendBlogListComponent} from '../../components/frontend-blog-list/frontend-blog-list.component';
import {FrontendBlogPageComponent} from '../../components/frontend-blog-page/frontend-blog-page.component';

import {NotFoundComponent} from '../../components/not-found/not-found.component';


import { AuthGuard } from "../../shared/guard/auth.guard";

const routes: Routes = [
// { path: '', redirectTo: '/sign-in', pathMatch: 'full'},

{ path: 'sign-in', component: SignInComponent},
{ path: 'register-user', component: SignUpComponent},
{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
{ path: 'dashboard-published-reports', component: DashboardReportsPublishedComponent, canActivate: [AuthGuard] },
{ path: 'dashboard-published-reports/:id', component: DashboardReportsPublishedUpdateComponent, canActivate: [AuthGuard] },

{ path: 'dashboard/:id', component: DashboardUpdateReportComponent, canActivate: [AuthGuard] },
{ path: 'dashboard-add-report',component:DashboardAddReportComponent,canActivate: [AuthGuard]},
// // { path: 'dashboard-blog',component:DashboardBlogComponent,canActivate: [AuthGuard]},
// // { path: 'dashboard-blog-list',component:DashboardBlogListComponent, canActivate: [AuthGuard]},
// // { path: 'dashboard-blog-update',component:DashboardBlogUpdateComponent,canActivate: [AuthGuard]},
// { path: 'dashboard-blog-list/:id',component:DashboardBlogUpdateComponent,canActivate: [AuthGuard]},
{ path: 'dashboard-page/:page',component:DashboardPageComponent,canActivate: [AuthGuard]},
// { path: 'dashboard-publication',component:DashboardPublicationsComponent,canActivate: [AuthGuard]},
// { path: 'dashboard-publication-list',component:DashboardPublicationListComponent,canActivate: [AuthGuard]},
// { path: 'dashboard-publication-list/:id',component:DashboardPublicationUpdateComponent,canActivate: [AuthGuard]},

{ path: '',component:FrontendHomeComponent},
{ path: 'add-report-success',component:FrontendAddReportSuccessComponent},
{ path: 'forgot-password', component: ForgotPasswordComponent },
{ path: 'verify-email-address', component: VerifyEmailComponent },
{ path: 'filter/:category',component:FrontendHomeComponent},
{ path: 'add-report',component:FrontendAddReportComponent},
{ path: 'page/:page',component:FrontendPageComponent},
// { path: 'publications',component:FrontendPublicationsComponent},
{ path: 'statistics',component:FrontendStatisticsComponent},
{ path: 'statistics/cat/:name',component:FrontendStatisticsComponent},

{ path: 'statistics/:id',component:FrontendStatisticsPostComponent},




// { path: 'blog',component:FrontendBlogListComponent},
// { path: 'blog/:post',component:FrontendBlogPageComponent},



{ path: '**', component: NotFoundComponent}



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
