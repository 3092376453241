<app-dashboard-header></app-dashboard-header>
<form [formGroup]="freportAdd" (ngSubmit)="onSubmit(freportAdd)">
	<div class="container page">
		<div class="row">
			<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"Поднеси нова пријава"|translate}}</div>
			<div class="col-12 title">{{'Поднеси нова пријава'| translate}}</div>
			<div class="col-12 req">{{"Полињата означени со * се задолжителни"|translate}}</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label>{{"Наслов"|translate}}</label>
					<span class="mandatory">*</span>
					<input type="text" id="title" formControlName="title" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
					<!-- <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
						<div *ngIf="f.title.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div> -->
				</div>
				<div class="form-group">
					<label for="report_desc">Ве молиме наведете што точно ви се случило, <br> споделете ја вашата приказна со нас</label>
					<span class="mandatory">*</span>
					<textarea class="form-control" id="description" formControlName="description"
					[ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
					<!-- <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
						<div *ngIf="f.description.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div> -->
				</div>



				<div class="group">
					<label for="blog_date">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
					<div class="row">
						<div class="col-6">		<input type="date" formControlName="reportDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.reportDate.errors }" />
						</div>
						<div class="col-6">		<input type="type" formControlName="time" placeholder="{{'Пр. 12:00, 12:35'|translate}}" class="form-control">
						</div>
					</div>
				<!-- <div *ngIf="submitted && f.reportDate.errors" class="invalid-feedback">
					<div *ngIf="f.reportDate.errors.required">									<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div> -->
			</div>
			<div class="group">
				<label for="reportCat">{{"Категории"|translate}}</label>
				<span class="mandatory">*</span>
				<div *ngFor="let category of categories; let i=index">
					<input type="checkbox"
					id="bla+{{category.id}}"  name="reportCat"  [value]="category.name"
					(change)="isCategorySelected($event,category.name)" id="{{category.name}}">
					<label for="{{category.name}}"> {{category.name | translate}}</label>
				</div>

				<div *ngIf="submitted && f.reportCat.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.reportCat.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>

			</div>
			<div class="form-group">
				<label for="report_i_i">{{"Дали ова беше инцидент или се случува континуирано? "|translate}}<span class="mandatory">*</span></label>
				<div *ngFor="let i_i of is_incident" >
					<input type="radio"  name="incident" formControlName="incident" value="{{i_i.name}}" id="{{i_i.name}}">
					<label for="{{i_i.name}}">{{i_i.name | translate}}</label>
				</div>
				<!-- <div *ngIf="submitted && f.youAre.errors" class="invalid-feedback mandatory_line">									
					<div *ngIf="f.youAre.errors.required"><span>{{'Ова поле е задолжително'|translate}}</span></div>
				</div> -->
			</div>


			<div class="form-group">
				<label for="report_type">{{"Тип на организација [работодавач]"|translate}}</label>
				<span class="mandatory">*</span>
				<div *ngFor="let type of org_type" >
					<input type="radio"  name="type" formControlName="type" value="{{type.name}}" id="{{type.name}}">
					<label for="{{type.name}}"> {{type.name | translate}}</label>
				</div>
				<!-- <div *ngIf="submitted && f.happened.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.happened.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div> -->
			</div>
			
			<div class="group">
				<label for="report_any">{{"Дали случајов го имате пријавено на друго место?"|translate}}</label><span class="mandatory">*</span>
				<div *ngFor="let reportAny of report_any" >
					<input type="radio" name="reportAny" formControlName="reportAny" 
					value="{{reportAny.name}}" id="{{reportAny.id}}_{{reportAny.name}}">
					<label for="{{reportAny.id}}_{{reportAny.name}}">{{reportAny.name | translate}}</label>
				</div>

				<!-- <div *ngIf="submitted && f.reportPolice.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.reportPolice.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div> -->
			</div>
			<div class="group">
				<label for="report_where">{{"Доколку имате пријавено, каде го направивте тоа?"|translate}}</label><span class="mandatory">*</span>
				<div *ngFor="let reportWhere of report_where">
					<input type="checkbox" name="reportWhere" value="reportWhere.name" 
					(change)="isReportWhereSelected($event,reportWhere.name)" id="{{reportWhere.id}}_{{reportWhere.name}}">
					<label for="{{reportWhere.id}}_{{reportWhere.name}}">{{reportWhere.name | translate}}</label>
				</div>
				<!-- <div *ngIf="submitted && f.policeAnswer.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.policeAnswer.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div> -->
			</div>
			<div class="form-group">
				<label>{{"Доколку вашиот одговор е Друго, ве молиме наведете каде имате пријавено"|translate}}</label>
				<input type="text" id="reportWhere_other" formControlName="reportWhere_other" class="form-control"
				[ngClass]="{ 'is-invalid': submitted && f.reportWhere.errors }" />
					<!-- <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
						<div *ngIf="f.title.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div> -->
				</div>

				<div class="group">
					<label>Основ за дискриминација.<br> Ве молиме наведете поради што сметате дека сте дискриминирани</label>
					<span class="mandatory">*</span>
					<div *ngFor="let discrimination of discrimination_for">
						<input type="checkbox" name="discrimination" value="discrimination.name" 
						(change)="isDiscriminationForSelected($event,discrimination.name)" id="{{discrimination.id}}_{{discrimination.name}}">
						<label for="{{discrimination.id}}_{{discrimination.name}}">{{discrimination.name | translate}}</label>
					</div>

				</div>

				<div class="form-group">
					<label for="report_desc">Ако сте лошо третирани поради друга причина, ве молиме наведете ја подолу</label>
					<span class="mandatory">*</span>
					<textarea class="form-control" id="discrimination_other" formControlName="discrimination_other"
					[ngClass]="{ 'is-invalid': submitted && f.discrimination_other.errors }"></textarea>
					<!-- <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
						<div *ngIf="f.description.errors.required">					<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div> -->
				</div>
			</div>
			<div class="col-6">
				<div style="font-size: 20px"><label class="notMandatory">{{"Незадолжителни полиња"|translate}}</label>
				</div>
				<div>
					<div>{{"Име и презиме"|translate}}</div>
					<input class="form-control" type="text" name="name_surname" formControlName="name_surname">
				</div>
				<div>
					<div>{{"Email"|translate}}</div>
					<input class="form-control" type="text" name="email" formControlName="email">
				</div>
				<div>
					<div>{{"Линк од вести"|translate}}</div>
					<input class="form-control" type="text" name="news_link" formControlName="news_link">
				</div>
				<div>
					<div>{{"Надворешен видео линк"|translate}}</div>
					<input class="form-control" type="text" name="news_link_2" formControlName="news_link_2">
				</div>
				<div>
					<div>{{"Прикачи фотографија"|translate}}</div>
					<input class="form-control" type="file"  (change)="uploadImage($event, f.title)" accept=".png,.jpg" >
					<div class="progress">
						<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
					</div>

				</div>
				<div style="font-size: 20px"><label class="notMandatory">{{"Незадолжителни полиња - Податоци за статистика"|translate}}</label>
				</div>

				<div class="col-12 group">
					<label for="report_type">{{"Пол"|translate}}</label>
					<div *ngFor="let gender of gender_list" >
						<input type="radio"  name="gender" formControlName="gender" value="{{gender.name}}" id="{{gender.name}}">
						<label for="{{gender.name}}"> {{gender.name | translate}}</label>
					</div>

				</div>
				<div class="col-12 group">
					<label for="report_type">{{"Место на живеење"|translate}}</label>
					<div *ngFor="let placeLive of place_live" >
						<input type="radio"  name="placeLive" formControlName="placeLive" value="{{placeLive.name}}" id="{{placeLive.name}}">
						<label for="{{placeLive.name}}"> {{placeLive.name | translate}}</label>
					</div>
				</div>

				<div class="col-12 group">
					<label for="report_type">{{"Кое е вашето последно завршено образование"|translate}}</label>
					<div *ngFor="let edu of education" >
						<input type="radio"  name="edu" formControlName="edu" value="{{edu.name}}" id="{{edu.name}}">
						<label for="{{edu.name}}"> {{edu.name | translate}}</label>
					</div>
				</div>

				<div class="col-12 group">
					<label for="report_type">{{"Работен однос"|translate}}</label>
					<div *ngFor="let workStatus of work_status" >
						<input type="radio"  name="workStatus" formControlName="workStatus" value="{{workStatus.name}}" id="{{workStatus.name}}">
						<label for="{{workStatus.name}}"> {{workStatus.name | translate}}</label>
					</div>
				</div>

				<div class="col-12 group">
					<label for="report_type">{{"Лични месечни приходи"|translate}}</label>
					<div *ngFor="let salary of salary_m" >
						<input type="radio"  name="salary" formControlName="salary" value="{{salary.name}}" id="{{salary.name}}">
						<label for="{{salary.name}}"> {{salary.name | translate}}</label>
					</div>
				</div>

				<div class="group">
					<label>{{"Работен стаж"|translate}}</label>
					<span class="mandatory">*</span>
					<input type="text" id="employStatus" formControlName="employStatus" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.employStatus.errors }" />
				</div>
				<div class="group">
					<label>{{"Како се информиравте за пријавава?"|translate}}</label>
					<span class="mandatory">*</span>
					<input type="text" id="info" formControlName="info" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.info.errors }" />
				</div>
				<button class="btn btn-primary" type="submit">{{"Поднеси пријава"|translate}}</button>
			</div>

		</div>

	</div>
</form>
<app-frontend-footer></app-frontend-footer>