import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { faEye,faChartBar,faFileAlt, faEnvelope,faPencilAlt,faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-frontend-home',
	templateUrl: './frontend-home.component.html',
	styleUrls: ['./frontend-home.component.scss']
})
export class FrontendHomeComponent implements OnInit {
	id;
	changeClass;
	allMarkers;
	categories;
	allCat;
	objectReportLength=[];
	faEye=faEye;
	faChartBar=faChartBar;
	faFileAlt=faFileAlt;
	faEnvelope=faEnvelope;
	faPencil=faPencilAlt;
	faDownload=faDownload;
	categoryName: string='';
	
	constructor(private rout:ActivatedRoute, private reportservice:ReportService, private router:Router) { }

	async ngOnInit() { 
		this.allCat=await this.reportservice.filterAndCountReports();
		for(var rep of this.allCat){
			this.objectReportLength.push({name:rep.name,length:rep.count});
		}
		this.categories=this.objectReportLength;
	}


	getTheCategory(name){
		this.categoryName=name;
	}

}
