<div class="container-fluid footer">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-4" style="text-align: center">
				<img src="assets/img/logo_mk-1.svg" style="height: 35px">
			</div>
			<div class="col-sm-12 col-md-8 centerItems">
			Овaa веб страна е изготвена со финансиска поддршка од Европската Унија. За содржината на веб сајтот целосно е одговорен Реактор и во никој случај не ги одразува позициите на Европската Унија. Повеќе за поддршката од ЕУ можете да дознаете на веб сајтот на Делегацијата на ЕУ или на EuropeAid.и зајакнување на жените (UN Women), Обединетите Нации или придружни организации.
			</div>
			<div class="col-sm-12 col-md-4" style="text-align: center">
				<img src="assets/img/logo-usaid.png"><br>
				<img src="assets/img/logo-foom.png">
			</div>
			<div class="col-sm-12 col-md-8 centerItems">
			Веб сајтот Пријави дискриминација е поддржана преку проектот на USAID за Граѓанско општество, имплементиран од страна на Фондација Отворено општество - Македонија. Информациите објавени на овој веб проект не се официјални информации на Владата на САД и не ги претставуваат погледите и позициите на Агенцијата на Соединетите Американски Држави за меѓународен развој или на Владата на САД.
			</div>
		</div>
		
	</div>
</div>