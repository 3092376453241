<app-frontend-header></app-frontend-header>
<div class="container page">
   <div class="row">
     <div class="col-12 subtext"><a href="/">{{"Почетна"|translate}}</a> / <a href="/statistics">{{"Поднесени пријави"|translate}}</a></div>

     <div class="col-12" >
      <div class="title">{{reportData?.title}}</div>
   </div>
   <div class="col-md-8 col-xs-12">
      <div class="report_row">
         <div>
            <span *ngIf="reportData?.time"><fa-icon [icon]="faClock"></fa-icon>
            {{reportData?.time}} </span><span> 
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
            {{reportData?.reportDate|date:'mediumDate'}}</span>  
         </div>
      </div>
      <div class="report_array report_row">
         <div  *ngFor="let cat of reportData?.reportCat">
            <span *ngIf="cat.ischecked" class="true">{{cat.name}}</span>
         </div>
      </div>
      <div class="report_row">
         <div class="subtitle">{{'Ве молиме наведете што точно ви се случило, споделете ја вашата приказна со нас '|translate}}</div>
         <div>{{reportData?.description}}</div>
      </div>
       <div *ngIf="reportData?.incident||reportData?.type||reportData?.reportAny|| reportData?.reportWhere_other||reportWhere||discriminationFor||reportData?.discriminationFor_other" class="subtitle">{{'Повеќе податоци'|translate}}</div>

      <div *ngIf="reportData?.incident">
         <div><b>{{'Дали ова беше инцидент или се случува континуирано? '|translate}}:</b></div>
         <p class="answ">{{reportData?.incident}}</p>
      </div>
      <div *ngIf="reportData?.type">
         <div><b>{{'Тип на организација [работодавач] '|translate}}:</b></div>
         <p class="answ">{{reportData?.type}}</p>
      </div>
      <div *ngIf="reportData?.reportAny">
         <div><b>{{'Дали случајов го имате пријавено на друго место?'|translate}}:</b></div>
         <p class="answ">{{reportData?.reportAny}}</p>
      </div>

      <div *ngIf="reportWhere">
         <div><b>{{'Доколку имате пријавено, каде го направивте тоа? '|translate}}:</b></div>
         <span class="answ" *ngFor="let cat of reportData?.reportWhere">
            <span *ngIf="cat.ischecked" class="true">{{cat.name}}, </span>
         </span>
      </div>
       <div *ngIf="reportData?.reportWhere_other">
         <div><b>{{'Доколку вашиот одговор е Друго, ве молиме наведете каде имате пријавено'|translate}}:</b></div>
         <span class="answ">{{reportData?.reportWhere_other}}</span>
      </div>

       <div *ngIf="discriminationFor">
         <div><b>{{'Основ за дискриминација. Ве молиме наведете поради што сметате дека сте дискриминирани'|translate}}:</b></div>
         <span class="answ" *ngFor="let dis of reportData?.discriminationFor">
            <div *ngIf="dis.ischecked" class="true">{{dis.name}}</div>
         </span>
      </div>

       <div *ngIf="reportData?.discrimination_other">
         <div><b>{{'Ако сте лошо третирани поради друга причина, ве молиме наведете ја подолу'|translate}}:</b></div>
         <span class="answ">{{reportData?.discrimination_other}}</span>
      </div>
      <div *ngIf="reportData?.image">
         <div style="margin-top:30px"><b>{{'Слика'|translate}}</b></div>
         <img src="{{reportData?.image}}">
      </div>
      <div *ngIf="reportData?.news_link || reportData?.news_link_2" >
         <div class="report_row" style="margin-top: 30px"><b>{{'Надворешни линкови за пријавата'|translate}}</b></div>
         <div *ngIf="reportData?.news_link">
            <span class="q">{{'Линк од вести'|translate}}:</span>
            <div>{{reportData?.news_link}}</div>
         </div>
         <div *ngIf="reportData?.news_link_2">
            <span class="q">{{'Надворешен видео линк'|translate}}:</span>
            <div>{{reportData?.news_link_2}}</div>
         </div>
      </div>
   </div>
   <div class="col-md-4 col-xs-12" style="border-left:1px solid #e6e6e6">

      <div class="last_five">
         <div class="report_row" style="color: #e93f33;"><b>{{'Последни пријави'| translate}}</b></div>
         <div *ngFor="let report of lastFiveReports" class="report_data" style="margin-bottom: 10px">
            <div class="report_static_date">
               <span style="color:#e93f33;padding:5px">
                  <fa-icon [icon]="faClock"></fa-icon>
               </span>
               {{report.reportDate|date:'mediumDate'}}
            </div>
            <a routerLink="/statistics/{{report?.id}}" >
               <div class="report_title">
                  <p class="answ five_rep_title">{{report?.title}}</p>
               </div>
            </a>

         </div>
      </div>
   </div>
</div>
</div>
<app-frontend-footer></app-frontend-footer>