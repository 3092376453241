<app-dashboard-header></app-dashboard-header>
<form [formGroup]="freportUpdate" (ngSubmit)="onSubmit(freportUpdate)">
	<div class="container">
		<div class="row">
			<div class="col-12 subtext"><a href="/dashboard">dashboard</a> / {{"Промени пријава"|translate}}</div>
			<div class="col-12 title">{{'Промени пријава'| translate}}</div>
		</div>
		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label>{{"Наслов на пријавата"|translate}}</label>
					<input type="text" name="title" class="form-control" formControlName="title" >
				</div>
				<div class="form-group">
					<label for="report_desc">{{"Опис на пријавата"|translate}}
					</label>
					<textarea class="form-control" id="description" name="description"
					formControlName="description"></textarea>
				</div>
				<div class="group">
					<label for="blog_date">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
					<input type="date" class="form-control" formControlName="reportDate" placeholder="YYYY-MM-dd">
					<input type="hidden" name="date" formControlName="date" class="form-control">
					<input type="hidden" name="time" formControlName="time" class="form-control">

				</div>
				<div class="form-group">
					<label >{{"Категории"|translate}}</label>
					<div *ngFor="let cat of freportUpdate.get('reportCat')?.controls;let i = index">
						<input type="checkbox"
						(change)="isCategorySelected($event,cat.value.name)" [checked]="cat.value.ischecked" id="{{cat.value.name+i}}" value="{{cat.value.name}}">
						<label for="{{cat.value.name}}"> {{cat.value.name}}</label>
					</div>
				</div>
				<div class="form-group">
					<label for="report_a_y">{{"Дали ова беше инцидент или се случува континуирано? "|translate}}<span class="mandatory">*</span></label>
					<div *ngFor="let i_i of is_incident" >
						<input type="radio"  name="incident" formControlName="incident" value="{{i_i.name}}" id="{{i_i.name}}">
						<label for="{{i_i.name}}">{{i_i.name | translate}}</label>
					</div>
				</div>
				<div class="form-group">
					<label for="report_type">{{"Тип на организација [работодавач]"|translate}}</label>
					<span class="mandatory">*</span>
					<div *ngFor="let type of org_type" >
						<input type="radio"  name="type" formControlName="type" value="{{type.name}}" id="{{type.name}}">
						<label for="{{type.name}}"> {{type.name | translate}}</label>
					</div>

				</div>
				
				<div class="group">
					<label for="report_police">{{"Дали случајов го имате пријавено на друго место?"|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let reportAny of report_any" >
						<input type="radio" name="reportAny" formControlName="reportAny" 
						value="{{reportAny.name}}" id="{{reportAny.id}}_{{reportAny.name}}">
						<label for="{{reportAny.id}}_{{reportAny.name}}">{{reportAny.name | translate}}</label>
					</div>
				</div>

				<div class="group">
					<label for="report_where">{{"Доколку имате пријавено, каде го направивте тоа?"|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let answer of freportUpdate.get('reportWhere')?.controls; let i=index">
						<input type="checkbox" name="reportWhere" value="{{answer.value.name}}" 
						(change)="isReportWhereSelected($event,answer.value.name)" id="_{{answer.value.name}}" [checked]="answer.value.ischecked">
						<label for="_{{answer.value.name}}">{{answer.value.name | translate}}</label>
					</div>

				</div>
				<div class="form-group">
					<label for="reportWhere_other">{{"Доколку вашиот одговор е Друго, ве молиме наведете каде имате пријавено"|translate}}
					</label>
					<textarea class="form-control" id="reportWhere_other" name="reportWhere_other"
					formControlName="reportWhere_other"></textarea>
				</div>
				<div class="group">
					<label for="report_where">{{"Основ за дискриминација. Ве молиме наведете поради што сметате дека сте дискриминирани."|translate}}</label><span class="mandatory">*</span>
					<div *ngFor="let dis of freportUpdate.get('discriminationFor')?.controls; let i=index">
						<input type="checkbox" name="reportWhere" value="{{dis.value.name}}" 
						(change)="isDiscriminationForSelected($event,dis.value.name)" id="_{{dis.value.name}}" [checked]="dis.value.ischecked">
						<label for="_{{dis.value.name}}">{{dis.value.name | translate}}</label>
					</div>

				</div>


				<div class="form-group">
					<label for="reportWhere_other">{{"Ако сте лошо третирани поради друга причина, ве молиме наведете ја подолу"|translate}}
					</label>
					<textarea class="form-control" id="discrimination_other" name="discrimination_other"
					formControlName="discrimination_other"></textarea>
				</div>
				<div class="group">
					<label class="notMandatory">{{"Податоци за сатистика"|translate}}</label>
					<div class="group">
						<label for="report_police">{{"Пол"|translate}}</label>
						<span class="mandatory">*</span>
						<div *ngFor="let gender of gender_list" >
							<input type="radio" name="gender" formControlName="gender" 
							value="{{gender.name}}" id="{{gender.id}}_{{gender.name}}">
							<label for="{{gender.id}}_{{gender.name}}">{{gender.name | translate}}</label>
						</div>
					</div>

					<div class="group">
						<label for="report_police">{{"Место на живеење"|translate}}</label>
						<span class="mandatory">*</span>
						<div *ngFor="let placeLive of place_live" >
							<input type="radio" name="placeLive" formControlName="placeLive" 
							value="{{placeLive.name}}" id="{{placeLive.id}}_{{placeLive.name}}">
							<label for="{{placeLive.id}}_{{placeLive.name}}">{{placeLive.name | translate}}</label>
						</div>
					</div>

					<div class="group">
						<label for="report_police">{{"Кое е вашето последно завршено образование"|translate}}</label>
						<span class="mandatory">*</span>
						<div *ngFor="let edu of education" >
							<input type="radio" name="edu" formControlName="edu" 
							value="{{edu.name}}" id="{{edu.id}}_{{edu.name}}">
							<label for="{{edu.id}}_{{edu.name}}">{{edu.name | translate}}</label>
						</div>
					</div>

					<div class="group">
						<label for="report_police">{{"Работен однос"|translate}}</label>
						<span class="mandatory">*</span>
						<div *ngFor="let workStatus of work_status" >
							<input type="radio" name="workStatus" formControlName="workStatus" 
							value="{{workStatus.name}}" id="{{workStatus.id}}_{{workStatus.name}}">
							<label for="{{workStatus.id}}_{{workStatus.name}}">{{workStatus.name | translate}}</label>
						</div>
					</div>
					<div class="group">
						<label for="report_police">{{"Лични месечни приходи"|translate}}</label>
						<span class="mandatory">*</span>
						<div *ngFor="let salary of salary_m" >
							<input type="radio" name="salary" formControlName="salary" 
							value="{{salary.name}}" id="{{salary.id}}_{{salary.name}}">
							<label for="{{salary.id}}_{{salary.name}}">{{salary.name | translate}}</label>
						</div>
					</div>
					<div>
						<div>{{"Работен стаж"|translate}}</div>
						<input class="form-control" type="text" name="employStatus" formControlName="employStatus">
					</div>
					<div>
						<div>{{"Како се информиравте за пријавава?"|translate}}</div>
						<input class="form-control" type="text" name="info" formControlName="info">
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="group">
					<div class="form-check allowReport">
						<input class="form-check-input" type="checkbox" 
						(change)="onCheckPlublished($event.target.checked)" formControlName="published" [checked]="published">
						<label class="form-check-label" for="flexCheckDefault">
							{{"Одобри"|translate}}
						</label>
					</div>
					<div *ngIf="submitted && published==false" class="invalid-feedback _published mandatory_line" >
						<div *ngIf="published==false">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
					<div>
						<label class="notMandatory">{{"Незадолжителни полиња - лични податоци"|translate}}</label>

						<div>{{"Име и презиме"|translate}}</div>
						<input class="form-control" type="text" name="name_surname" formControlName="name_surname">
					</div>
					<div>
						<div>{{"Email"|translate}}</div>
						<input class="form-control" type="text" name="email" formControlName="email">
					</div>
					<div>
						<div>{{"Линк од вести"|translate}}</div>
						<input class="form-control" type="text" name="news_link" formControlName="news_link">
					</div>
					<div>
						<div>{{"Надворешен видео линк"|translate}}</div>
						<input class="form-control" type="text" name="news_link_2" formControlName="news_link_2">
					</div>
					<div>
						<img src="{{downloadURL}}" name="image" width="200"></div>
						<div>
							<div>{{"Прикачи фотографија"|translate}}</div>
							<input class="form-control" type="file"  (change)="uploadImage($event, f.title)" accept=".png,.jpg" >
							<div class="progress">
								<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>
						<button class="btn btn-primary" type="submit" style="margin-right: 10px">{{"Потврди"|translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</form>
	<app-frontend-footer></app-frontend-footer>