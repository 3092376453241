<app-frontend-header></app-frontend-header>

<div class="before-page">
	<div class="container" style="background: white">
		<div class="row">
			<div class="col-md-5" style="padding: 0">
				<app-highcharts [categoryName]="categoryName"></app-highcharts>
			</div>
			<div class="col-md-7">
				<div class="row">
					<div *ngFor="let report of categories; let i = index" [class.active]="i == currentIndex" class="col-md-4 home-cat-main-box">
						<div style="height: 200px;width: 100%;background: #b3b3b3 ">
							<div class="home-cat-box home-cat-box-box">
								<div class="home-cat-text">
									<div class="home-cat-name">{{report.name|translate}}</div>
									<div class="home-cat-count-box"> 
										<div class="home-cat-count-text">{{report.length}}</div></div>	
									</div>
									<div class="icons">
										<span style="padding:5px">
											<a href="/statistics?catName={{report.name}}" placement="top" ngbTooltip="Преглед на пријави">
												<fa-icon [icon]="faEye"></fa-icon></a>
											</span>
											<span style="padding:5px" placement="top" ngbTooltip="Графички приказ на пријави " (click)="getTheCategory(report.name)">
												<fa-icon [icon]="faChartBar"></fa-icon>
											</span>
											<span style="padding:5px" placement="left" ngbTooltip="Поднеси пријава">
												<a href="/add-report">
													<fa-icon [icon]="faFileAlt"></fa-icon></a>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container" style="background: white;
				margin-top: 20px;
				margin-bottom: 20px;">
				<div class="row">
					<div class="col-md-12 home_box_title">Како да пријавам дискриминација на работно место?</div>
					<div class="col-md-4 home_box_">
						<a href="mailto:prijavi@prijavidiskriminacija.mk">
							<div class="img">
								<fa-icon [icon]="faEnvelope"></fa-icon>
							</div>
							Ви треба помош околу поднесувањето на пријавата? 
							Можете слободно да не исконтактирате на нашата email адреса prijavi@prijavidiskriminacija.mk <br>ИСПРАТИ EMAIL
						</a>
					</div>
					<div class="col-md-4 home_box_">
						<a href="/add-report">
							<div class="img"><fa-icon [icon]="faPencil"></fa-icon>
							</div>
							Пополнете ја онлаин формата на нашиот веб сајт, пријавете дискриминација на работно место, споделете приказна со нас, спречете дискриминација. <br>ПОПОЛНИ ФОРМУЛАР
						</a>
					</div>
					<div class="col-md-4 home_box_">
						<a href="assets/prijava_diskriminacija.pdf" download>
							<div class="img"><fa-icon [icon]="faDownload"></fa-icon></div>
							Не се чувствувате слободно да ја пополните пријавата на нашиот веб сајт, тука можете да ја симнете пријавата, а потоа пополнета да ја испратете на нашата еmail адреса. <br>СИМНИ ФОРМУЛАР
						</a>
					</div>

				</div>
			</div>
			
			<app-frontend-footer></app-frontend-footer>
		</div>